import { FivethreeCoreModule } from '@fivethree/core';
import { NgModule, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MarkdownModule } from 'ngx-markdown';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LottieAnimationViewModule } from 'ng-lottie';
import { UtilService } from '@services/util.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ApolloModule, Apollo } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { GlobalErrorHandler } from './services/GlobalErrorHandler';
import { IonicSelectableModule } from 'ionic-selectable';
import { EmbedVideo } from 'ngx-embed-video';
import { PendingChangesGuard } from './services/deactivate_test';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { AndroidFullScreen } from '@ionic-native/android-full-screen/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { AgGridModule } from 'ag-grid-angular';
import { BtnCellRenderer } from './table_components/buttons';
import {NgxPrintModule} from 'ngx-print';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MyCurrencyPipe } from './pipe/currency.pipe';
import { AngularEditorModule } from '@kolkov/angular-editor';
 
@NgModule({
  declarations: [AppComponent,MyCurrencyPipe],
  entryComponents: [],
  imports: [
    BrowserModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    FivethreeCoreModule,
    IonicModule.forRoot({animated: false}),
    AppRoutingModule,
    ApolloModule,
    AgGridModule.withComponents([]),
    EmbedVideo.forRoot(),
    NgxPrintModule,
    HttpLinkModule,
    IonicSelectableModule,
    CKEditorModule,
    AngularEditorModule,
    HttpClientModule,
    MarkdownModule.forRoot({ loader: HttpClient }),
    LottieAnimationViewModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    OneSignal,
    Deeplinks,
    DatePipe,
    CurrencyPipe,
    MyCurrencyPipe,
    AndroidFullScreen,
     PendingChangesGuard,
    // {provide: ErrorHandler, useClass: GlobalErrorHandler},
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    UtilService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
   
}
