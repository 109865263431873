import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DataService } from '../../services/data.service';
 
import { LoadingService } from '../../services/loading.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.page.html',
  styleUrls: ['./change-password.page.scss'],
})
export class ChangePasswordPage implements OnInit {
  code = null;
  user :FormGroup;
  constructor(
        private activatedRoute: ActivatedRoute,
        public formBuilder : FormBuilder, 
        public data:DataService,
        public router : Router,
        public loading : LoadingService
      ) {


        if(this.data.is_logged_in()){

          this.router.navigate(['/admin/dashboard']);
        }
        this.data.hide_split(true);
    
        this.user = this.formBuilder.group({
                
          password: new FormControl('', Validators.required),
          passwordConfirmation: new FormControl('', Validators.required),
          code: new FormControl('', Validators.required)
       });

      this.activatedRoute.params.subscribe(params => {
            this.code = params['id'];

              console.log(this.code);
            this.user = this.formBuilder.group({
                
                password: new FormControl('', Validators.required),
                passwordConfirmation: new FormControl('', Validators.required),
                code: new FormControl(this.code, Validators.required)
            });

      });
 
  }
  ngOnInit() {
  }

  ionViewWillEnter(){
    this.data.hide_split(true);
  }


  onSubmit(data){
    console.log(data);
    this.loading.present("Updating Password").then(v => {
      this.data.post_open('auth/reset-password',data).subscribe(data => {
        this.loading.dismiss();
        let u = data['user'];
        if(u.role.name === 'student'){
          sessionStorage.setItem('token',data['jwt']);

          this.data.saveToLocal(data['user']);
          
          this.router.navigate(['/dashboard']);
          this.loading.presentToast('Password Updated Successfully')
            
       
        }else{
          this.loading.alert('Error', ' An Error Occured Please Check Your Email Or Password');
        }
        console.log(data);
      },error => {
        this.loading.dismiss();
        this.loading.alert('Error',error.error.message[0].messages[0].message);
      })
    })
  }



}
