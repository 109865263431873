import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from 'graphql-tag';
import { HttpHeaders } from '@angular/common/http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
@Injectable({
  providedIn: 'root'
}) 
 
export class GraphQlService {

  constructor(private apollo: Apollo, httpLink: HttpLink) {

    const link = onError(({ graphQLErrors, networkError }) => {
      if(graphQLErrors){
        graphQLErrors.map(({ message, locations, path }) => {
          // Here you may display a message to indicate graphql error
          // You may use 'sweetalert', 'ngx-toastr' or any of your preference
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
          console.log(message)
          if(message === 'Forbidden'){
            localStorage.clear();
            window.location.reload();
            return
          }
          if(message === 'Invalid token.'){
            localStorage.clear();
            window.location.reload();
            return
          }
        })
      }
      if(networkError){
          // Here you may display a message to indicate network error
          console.log(`[Network error]: ${networkError}`);
      }
    });


    apollo.create({
      // link : ApolloLink.from([link, httpLink.create({ uri: "https://api.winmall.ae/graphql" })]) ,
      link : ApolloLink.from([link, httpLink.create({ uri: "https://api.ifast.ae/graphql" })]) ,

      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          errorPolicy: 'all',
          
        }
      }
      
      
    })
  }

  public get = (data,hard = false) => {
   return this.apollo.query({
      query: gql(data),context: {
        headers: new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('token')),
      },fetchPolicy:hard ? 'network-only' : 'cache-first'
    })
  }
 

  
  public post = (data) => {
   return this.apollo.mutate({
    mutation: gql(data),context: {
        headers: new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('token')),
      }
    })
  }



  queryfy(obj) {
    // Make sure we don't alter integers.
    if (typeof obj === 'number') {
      return obj;
    }
  
    if (Array.isArray(obj)) {
      const props = obj.map(value => `${this.queryfy(value)}`).join(',');
      return `[${props}]`;
    }
  
    if (typeof obj === 'object') {
      const props = Object.keys(obj)
        .map(key => `${key}:${this.queryfy(obj[key])}`)
        .join(',');
      return `{${props}}`;
    }
  
    return JSON.stringify(obj);
  
}
}
