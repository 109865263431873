import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
 
 
import { DataService } from "./data.service";
 
@Injectable({
  providedIn: "root"
})
export class AuthGuardService implements CanActivate {
  auth = false;
  constructor(private router: Router ,public data : DataService) {
    
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
   // console.log('authgaurd')
    //console.log(role);
    let token; 
    token = localStorage.getItem('token');
    console.log(token);
    if(token == null){
     
      this.auth = false;
      this.router.navigate(['/login']);
     
    }
    else if(token == 'undefined'){
      this.auth = false;
      this.router.navigate(['/login']);
      
      
    }
    else if(token == ''){
      this.auth = false;
      this.router.navigate(['/login']);
      
    }else{
      this.auth = true;
      
      return true;
    }
     
    
  }


  logout(){
    this.auth = false;
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  is_logged_in(){
    return this.auth;
  }


  validate_user(val){
   console.log('validating user');
   this.data.validate_user(val).subscribe(data => {
     console.log(data);
     if(data['id']){
       this.auth = true;
     }
     else{
       this.auth = false;
       this.router.navigate(['/login']);
     }
   },error => {
     console.log(error);
     this.router.navigate(['/login']);
   })

  }



    
}