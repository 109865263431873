import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { convertCurrency, getCurrencyRate, getCurrencyRateList } from 'currencies-exchange-rates';
import { DomSanitizer } from '@angular/platform-browser';
import { MyCurrencyPipe } from '../pipe/currency.pipe';
@Injectable({
  providedIn: 'root'
})
export class DataService {

  // bs = "https://api.winmall.ae" 
  // base_url = "https://api.winmall.ae/"  
  // hs = "https://admin.winmall.ae/"


  bs = "https://api.ifast.ae" 
  base_url = "https://api.ifast.ae/"  
  hs = "https://admin.ifast.ae/"

  // bs = "https://stageing.winmall.ae"
  // base_url = "https://stageing.winmall.ae/"
  token;
  show = false;
  selected_currency;
  base_currency: string;
  
  constructor(private http: HttpClient, private _sanitizer: DomSanitizer, public currency: MyCurrencyPipe) {
    this.token = localStorage.getItem('token');
  }


  format_currency(value, currency) {
    return this.currency.transform(value, currency);
  }

  setCurrency() {
    this.http.get(this.base_url + 'currencies', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).subscribe(data => {
      console.log(data);

      localStorage.setItem('currency', JSON.stringify(data))
      this.set_default_currency()
    })
  }

  set_default_currency() {
    let dataa = localStorage.getItem('currency');
    let data: [] = JSON.parse(dataa);
    if (localStorage.getItem('default_currency')) {
      let index = data.findIndex((i) => {
        return i['currency_code'] == localStorage.getItem('default_currency')
      })
      this.selected_currency = data[index]
    } else {
      let index = data.findIndex((i) => {
        return i['is_default'] == true
      })
      this.selected_currency = data[index];
      localStorage.setItem('default_currency', this.selected_currency.currency_code)
    }
  }

  format_currency_base(val, from_currency) {

    let dataa = localStorage.getItem('currency');
    let data: [] = JSON.parse(dataa);
    let index = data.findIndex((i) => {
      return i['currency_code'] == from_currency
    })
    let conversion = data[index] ? data[index]['conversion_rate'] : 1 ;  
    
    if (this.selected_currency) {
      return this.currency.transform(val / conversion, this.selected_currency['currency_code'])
    }
  }

  public is_logged_in() {
    var token = localStorage.getItem('token');
    if (token == null) {

      return false;

    }
    else if (token == 'undefined') {
      return false;

    }
    else if (token == '') {
      return false;

    } else {


      return true;
    }

  }


  convert_currency(from, amount, date = null) {
    let base_currency = localStorage.getItem('base_currency');
    if (base_currency) {
      return convertCurrency(from, base_currency, amount, date)
    } else {
      return convertCurrency(from, 'USD', amount, date)
    }

  }

  public split() {
    return this.show;
  }

  public hide_split(bool) {
    this.show = bool;
  }

  safe_url(link) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(link);
  }

  public validate_user(val) {
    return this.http.get(this.base_url + 'users/me', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + val
      })
    })
  }

  public saveToLocal(data) {
    localStorage.setItem('vals', JSON.stringify(data));
  }

  public getLocal(key, additional = null) {
    let data = localStorage.getItem('vals');
    data = JSON.parse(data);
    data = data[key];
    if (additional != null) {
      data = data[0][additional]
    }
    return data;
  }



  public register(data) {
    return this.http.post(this.base_url + 'auth/local/register', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  public login(data) {
    return this.http.post(this.base_url + 'admin/login', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })

  }
  public postALL(url, data) {
    return this.http.post(this.base_url + url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })

  }


  public post(url, data) {
    return this.http.post<any[]>(this.base_url + url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + localStorage.getItem('token')
      })
    })
  }


  public post_open(url, data) {
    return this.http.post<any[]>(this.base_url + url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'

      })
    })
  }

  public put(url, data) {
    return this.http.put(this.base_url + url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + localStorage.getItem('token')
      })
    })
  }

  public get(url) {
    return this.http.get<any[]>(this.base_url + url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + localStorage.getItem('token')
      })
    })
  }


  public getPrint(url) {
    return this.http.get<string>(this.base_url + url, {
      headers: new HttpHeaders({
        'Content-Type': 'text/html',
        'Authorization': `Bearer ` + localStorage.getItem('token')
      })
    })
  }


  public get_public(url) {
    return this.http.get<any[]>(this.base_url + url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })
  }

  public delete(url) {
    return this.http.delete(this.base_url + url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + localStorage.getItem('token')
      })
    })
  }


  postFile(fileToUpload: File) {
    const endpoint = this.base_url + 'upload';
    const formData: FormData = new FormData();
    formData.append('files', fileToUpload);
    return this.http
      .post(endpoint, formData, {
        headers: new HttpHeaders({

          'Authorization': `Bearer ` + localStorage.getItem('token')
        })
      })


  }


  upload_doubt(fileToUpload: File, doubt) {
    const data = {};
    data['from'] = this.getLocal('id');
    data['doubt'] = doubt;
    const endpoint = this.base_url + 'doubt-conversations';
    const formData: FormData = new FormData();
    formData.append('files.media', fileToUpload);
    formData.append('data', JSON.stringify(data));


    return this.http
      .post(endpoint, formData, {
        headers: new HttpHeaders({

          'Authorization': `Bearer ` + localStorage.getItem('token')
        })
      })
  }

  postFiles(files: FileList) {


    const endpoint = this.base_url + 'upload';
    const formData: FormData = new FormData();
    let x = 0;
    for (x = 0; x < files.length; x++) {
      formData.append('files', files[x]);
    }

    return this.http
      .post<any[]>(endpoint, formData, {
        headers: new HttpHeaders({

          'Authorization': `Bearer ` + localStorage.getItem('token')
        })
      })


  }

  validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  fetch_thumb(id, provider) {
    var that = this
    let p = new Promise((resolve, reject) => {
      if (id) {
        if (provider == 'youtube') {
          this.http.get('https://www.googleapis.com/youtube/v3/videos?id=' + id + '&key=AIzaSyCpCIwDiWt4i8IALPZ8J8ZbTfTTMq8czmk&part=snippet,contentDetails').subscribe(data => {
            resolve(data['items'][0].snippet.thumbnails.default.url)
          })
        } else if (provider == 'vimeo') {
          this.http.get<any[]>("https://api.vimeo.com/videos/" + id, {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': `Bearer 7c009ba3354041f824533087dbeda497`
            })
          }).subscribe(data => {
            let sizes = data['pictures'].sizes[2].link
            resolve(sizes)
          })
        } else {
          resolve('assets/logo.png')
        }
      } else {
        resolve('assets/logo.png')
      }
    })

    return p

  }



  convert_time_youtube(duration) {
    var a = duration.match(/\d+/g);

    if (duration.indexOf('M') >= 0 && duration.indexOf('H') == -1 && duration.indexOf('S') == -1) {
      a = [0, a[0], 0];
    }

    if (duration.indexOf('H') >= 0 && duration.indexOf('M') == -1) {
      a = [a[0], 0, a[1]];
    }
    if (duration.indexOf('H') >= 0 && duration.indexOf('M') == -1 && duration.indexOf('S') == -1) {
      a = [a[0], 0, 0];
    }

    duration = 0;

    if (a.length == 3) {
      duration = duration + parseInt(a[0]) * 3600;
      duration = duration + parseInt(a[1]) * 60;
      duration = duration + parseInt(a[2]);
    }

    if (a.length == 2) {
      duration = duration + parseInt(a[0]) * 60;
      duration = duration + parseInt(a[1]);
    }

    if (a.length == 1) {
      duration = duration + parseInt(a[0]);
    }
    return duration / 60;
  }

}
