import { Component, Inject, NgZone } from '@angular/core';

import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FivRoutingStateService } from '@fivethree/core';
import { DOCUMENT } from '@angular/common';
import { DataService } from './services/data.service';
import { AndroidFullScreen } from '@ionic-native/android-full-screen/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
 
import { Router } from '@angular/router';
import { DashboardPage } from './main/dashboard/dashboard.page';
import { ChangePasswordPage } from './system/change-password/change-password.page';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public docs = [
   
  ];
  public services = [];

  constructor(
    private androidFullScreen: AndroidFullScreen,
    private platform: Platform,
    private oneSignal: OneSignal,
    private zone: NgZone,
    private router : Router,
    private deeplinks: Deeplinks,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private routing: FivRoutingStateService,
    private navCtrl: NavController,
    @Inject(DOCUMENT) private document: Document,
    public data : DataService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.routing.loadRouting({ clearOn: ['/'], root: '/' });
      this.setupThemeListener();
      this.fullscreen();
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.initOnesignal()
      this.links();
      this.set_currency();
    });
  }
  set_currency() {
   this.data.setCurrency();
  }

  links(){
    this.deeplinks.route({
      '/reset-password': ChangePasswordPage,
      '': DashboardPage,
    })
    .subscribe((match) => {
      console.log(match)
      this.zone.run(() => {
            let d = match.$link.queryString
            console.log(match.$link.queryString);
            console.log(d.replace('code=',''));
            this.router.navigate([match.$link.path,d.replace('code=','')]); // path would be like /auth/password/reset/123xyz
        });
    });
  }



  fullscreen(){
    this.androidFullScreen.isImmersiveModeSupported()
    .then(() => {
      console.log('Immersive mode supported')
      //this.androidFullScreen.leanMode();
    })
    .catch(err => console.log(err));


  }
  navigateRoot(url: string) {
    this.navCtrl.navigateRoot(url);
  }
  navigate(url: string) {
    this.navCtrl.navigateForward(url);
  }

  private setupThemeListener() {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

    this.toggleTheme(prefersDark.matches);

    prefersDark.addEventListener('change', mediaQuery =>
      this.toggleTheme(mediaQuery.matches)
    );
  }



  initOnesignal(){
    // this.oneSignal.startInit('31dc4b9a-0d6a-4cde5-bbe0-1950c15c7929', '10898721136d80');

    // this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);
    
    // this.oneSignal.handleNotificationReceived().subscribe(() => {
    //  // do something when notification is received
    // });
    
    // this.oneSignal.handleNotificationOpened().subscribe(() => {
    //   // do something when a notification is opened
    // });
    
    // this.oneSignal.endInit();
   }

  private toggleTheme(shouldAdd: boolean) {
    this.document.body.classList.toggle('dark', shouldAdd);
  }
}
