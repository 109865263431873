import { Component, OnDestroy, Input } from '@angular/core';

import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'btn-cell-renderer',
  template: `
    <ion-button color='secondary' class="view" style="position: relative;top: -5px;" (click)="btnClickedHandler($event)"><ion-icon name="eye"></ion-icon></ion-button>
     
    
  `,
})

export class dashboardView implements OnDestroy {
 
  private params: any;

  agInit(params: any): void {
    this.params = params; 
  }
 

  btnClickedHandler(data) {
      
    this.params.clicked(this.params.data);
  }
  
  btnClickedHandler2(data) {
      console.log(this.params)
    this.params.del(this.params.data);
  }

  ngOnDestroy() {
    // no need to remove the button click handler 
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }

}
