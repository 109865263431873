import { NgModule } from '@angular/core';
import { Routes, RouterModule,PreloadAllModules } from '@angular/router';
import { AuthGuardService } from './services/auth-gaurd.service';
 

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch : 'full'
  },
  { path: 'dashboard', loadChildren: './main/dashboard/dashboard.module#DashboardPageModule',
    canActivate: [AuthGuardService]  },
  

  { path: 'login', loadChildren: './system/login/login.module#LoginPageModule' },
   

  { path: 'campaigns', loadChildren: './winmall/campaigns/campaigns.module#CampaignsPageModule',
  canActivate: [AuthGuardService] },
  { path: 'products', loadChildren: './winmall/products/products.module#ProductsPageModule',
  canActivate: [AuthGuardService] },
  { path: 'orders', loadChildren: './winmall/orders/orders.module#OrdersPageModule',
  canActivate: [AuthGuardService] },
  { path: 'campaign-category', loadChildren: './winmall/campain-category/campain-category.module#CampainCategoryPageModule',
  canActivate: [AuthGuardService] },
  { path: 'transactions', loadChildren: './winmall/transactions/transactions.module#TransactionsPageModule',
  canActivate: [AuthGuardService] },
  { path: 'tickets', loadChildren: './winmall/tickets/tickets.module#TicketsPageModule',
  canActivate: [AuthGuardService] },
  { path: 'winners', loadChildren: './winmall/winners/winners.module#WinnersPageModule',
  canActivate: [AuthGuardService] },
  { path: 'users', loadChildren: './winmall/users/users.module#UsersPageModule',
  canActivate: [AuthGuardService] },
  { path: 'user-level', loadChildren: './winmall/user-level/user-level.module#UserLevelPageModule',
  canActivate: [AuthGuardService] },
  { path: 'home-page', loadChildren: './winmall/user-points/user-points.module#UserPointsPageModule',
  canActivate: [AuthGuardService] },
  { path: 'currency', loadChildren: './winmall/currency/currency.module#CurrencyPageModule',
  canActivate: [AuthGuardService] },
  { path: 'social', loadChildren: './winmall/social/social.module#SocialPageModule',
  canActivate: [AuthGuardService] },
  { path: 'account', loadChildren: './winmall/account/account.module#AccountPageModule',
  canActivate: [AuthGuardService] },
  { path: 'cms', loadChildren: './winmall/cms/cms.module#CmsPageModule',
  canActivate: [AuthGuardService] },
  { path: 'faq', loadChildren: './winmall/faq/faq.module#FaqPageModule',
  canActivate: [AuthGuardService] },
  { path: 'contact', loadChildren: './winmall/contact/contact.module#ContactPageModule',
  canActivate: [AuthGuardService] },
  { path: 'default', loadChildren: './winmall/deault/deault.module#DeaultPageModule',
  canActivate: [AuthGuardService] },
  { path: 'home-page-banners', loadChildren: './winmall/home-page-banners/home-page-banners.module#HomePageBannersPageModule',
  canActivate: [AuthGuardService] },
  { path: 'sponsers', loadChildren: './winmall/sponsers/sponsers.module#SponsersPageModule',
  canActivate: [AuthGuardService] },
  { path: 'charities', loadChildren: './winmall/charity/charity.module#CharityPageModule',
  canActivate: [AuthGuardService] },
  { path: 'page-banners', loadChildren: './winmall/page-banners/page-banners.module#PageBannersPageModule' ,
  canActivate: [AuthGuardService]},
  { path: 'admin-settings', loadChildren: './winmall/panel/panel.module#PanelPageModule',
  canActivate: [AuthGuardService] },
  { path: 'charity-report', loadChildren: './winmall/reports/charity-report/charity-report.module#CharityReportPageModule',
  canActivate: [AuthGuardService] },
  { path: 'campaign-report', loadChildren: './winmall/reports/campaign-report/campaign-report.module#CampaignReportPageModule',
  canActivate: [AuthGuardService] },
  { path: 'orders-report', loadChildren: './winmall/reports/orders-report/orders-report.module#OrdersReportPageModule',
  canActivate: [AuthGuardService] },
  { path: 'product-report', loadChildren: './winmall/reports/product-report/product-report.module#ProductReportPageModule',
  canActivate: [AuthGuardService] },
  { path: 'transaction-report', loadChildren: './winmall/reports/transaction-report/transaction-report.module#TransactionReportPageModule',
  canActivate: [AuthGuardService] },
  { path: 'ticket-report', loadChildren: './winmall/reports/ticket-report/ticket-report.module#TicketReportPageModule',
  canActivate: [AuthGuardService] },
  { path: 'user-report', loadChildren: './winmall/reports/user-report/user-report.module#UserReportPageModule',
  canActivate: [AuthGuardService] },
  
  { path: 'notification', loadChildren: './winmall/notification/notification.module#NotificationPageModule',
  canActivate: [AuthGuardService] },
  
  { path: 'notification-send', loadChildren: './winmall/notification-send/notification-send.module#NotificationSendPageModule' ,
  canActivate: [AuthGuardService]},
  
  { path: 'email-templates', loadChildren: './winmall/email/email.module#EmailPageModule',
  canActivate: [AuthGuardService] },
 
  { path: 'winner-report', loadChildren: './winmall/reports/winner-report/winner-report.module#WinnerReportPageModule',
  canActivate: [AuthGuardService] },
  { 
    path: '**',
    loadChildren:
      './pages/page-not-found/page-not-found.module#PageNotFoundPageModule'
  },
  
  
 
  
  
  
  
 
  
 
  
 
  
   
 
 
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
       preloadingStrategy: PreloadAllModules // <-This is our preloading
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
