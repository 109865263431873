import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { DataService } from '../../services/data.service';
import { LoadingService } from '../../services/loading.service';
import urlParser from "js-video-url-parser";
import { GraphQlService } from '../../services/graph-ql.service';
import * as Plyr from 'plyr';
import { DatePipe } from '@angular/common';
import { BtnCellRenderer } from '../../table_components/buttons';
import { TicketBtnCellRenderer } from '../../table_components/ticket.button';
import { dashboardView } from '../../table_components/dashboard.view';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage implements OnInit {
  columnDefs = [];
  columnDefs2 = [];
  columnDefs3 = [];
  url = ''
  private gridApi;
  private gridColumnApi;
  frameworkComponents;

  private defaultColDef;
  rowData = []
  rowData2 = []
  rowData3 = []
  stats = {
    total_users: 0,
    total_campaigns: 0,
    total_pending_orders: 0,
    total_income: '00'
  }
  constructor(public datePipe: DatePipe, public router: Router, public data: DataService, public graphql: GraphQlService, public loading: LoadingService) {

    this.columnDefs = [
      {
        headerName: "Sno", valueGetter: "node.rowIndex + 1", width: 90
      },
      { headerName: 'ID', field: 'id', sortable: true, filter: true, width: 90 },
      {
        headerName: 'User', valueGetter: (params => {
          return params.data.user ? (params.data.user.first_name + ' ' + params.data.user.last_name) : 'User Deleted'
        }), sortable: true, filter: true
      },
      { headerName: 'Amount', field: 'transaction.amount', sortable: true, filter: true },
      {
        headerName: 'Created At', valueGetter: (params => {
          return this.datePipe.transform(params.data.created_at, 'dd/MM/yyyy hh:mm');
        })
      },
      {
        headerName: 'Options', cellRenderer: 'btnCellRenderer',
        cellRendererParams: {
          clicked: (field: any) => {
            this.router.navigate(['/orders/details', field.id])
          },
        }
      }
    ];
    this.columnDefs3 = [
      {
        headerName: "Sno", valueGetter: "node.rowIndex + 1", width: 90
      },
      { headerName: 'ID', field: 'id', width: 90 },
      {
        headerName: 'Active', valueGetter: (params => {
          return params.data.active ? 'Active' : 'Not Active';
        }), sortable: true, filter: true
      },
      { headerName: 'Capmaign Category', field: 'capmaign_category.cat_name_english', sortable: true, filter: true },
      { headerName: 'Product', field: 'product.product_name_english', sortable: true, filter: true },
      { headerName: 'Price', field: 'price', sortable: true, filter: true },
      {
        headerName: 'Start Date', valueGetter: (params => {
          return this.datePipe.transform(params.data.start_date_time, 'dd/MM/yyyy');
        })
      },

      {
        headerName: 'Sold/Stock', valueGetter: (params => {
          return params.data.total_sold + '/' + params.data.total_stock;
        })
      },
      {
        headerName: 'Options', cellRenderer: 'btnCellRenderer',
        cellRendererParams: {
          clicked: (field: any) => {

            this.router.navigate(['/campaigns/details', field.id])
          },


        }
      }
    ];
    this.columnDefs2 = [
      {
        headerName: "Sno", valueGetter: "node.rowIndex + 1", width: 90
      },
      { headerName: 'ID', field: 'id', width: 90 },
      {
        headerName: 'User', valueGetter: (params => {
          return this.loading.toProperCase(params.data.first_name) + ' ' + this.loading.toProperCase(params.data.last_name);
        }), sortable: true, filter: true
      },
      { headerName: 'Email', field: 'email', filter: true },
      // {headerName: 'Phone', field: 'phone', filter: true },

      {
        headerName: 'Level', valueGetter: (params => {
          return this.loading.toProperCase(params.data.level.level_english);
        })
      },
      {
        headerName: 'Options', cellRenderer: 'btnCellRenderer',
        cellRendererParams: {
          clicked: (field: any) => {
            this.router.navigate(['/users/details', field.id])

          },
        }
      }
    ];
    this.defaultColDef = { resizable: true };
    this.frameworkComponents = {
      btnCellRenderer: dashboardView
    }
  }

  ionViewDidEnter() {

    this.load_data();
    this.data.hide_split(false);
    this.data.get('get_total_sale').subscribe(data => {
      localStorage.setItem('base_currency', data['base_currency'].currency_code);
      this.stats.total_income = this.data.format_currency(data['total'], data['base_currency'].currency_code)
    })
  }



  load_data() {


    let q = `query{
     orders(where:{order_status:"pending"}) {
       id
       charity
       user{
         id
         first_name
         last_name
       }
       transaction{
         id
         amount
       }
       created_at
      },
      users(limit:9,sort:"id:DESC"){
        id
        first_name
        last_name
        created_at
        email
        level{
          id
          level_english
        }
        phone
      },
      capmaigns(where:{active:true}) {
        id
        active
        capmaign_category{
          id
          cat_name_english
        }
        product{
          product_name_english
          id
        }
        start_date_time
        price
        total_sold
        total_stock
      },
      
      unactive:ordersConnection(where:{order_status:"pending"}) {
        aggregate{
          count
        }
      },
      total:usersConnection {
        aggregate{
          count
        }
      },
 
     
    }`;

    this.graphql.get(q, true).subscribe(data => {
      console.log(data)
      this.rowData = data.data['orders'];
      this.rowData2 = data.data['users'];
      this.rowData3 = data.data['capmaigns'];

      this.stats.total_pending_orders = data.data['unactive']['aggregate']['count'];
      this.stats.total_users = data.data['total']['aggregate']['count'];
    })
  }


  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

  }

  ngOnInit() {
    this.data.hide_split(false);


  }

  ionViewWillLeave() {


  }

}
