import { Injectable } from '@angular/core';
import { LoadingController, AlertController } from '@ionic/angular';
import { MenuController, ToastController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class LoadingService {
_loading
  constructor(public loadingcontroller : LoadingController,
              public alertController : AlertController,
              public toastController: ToastController,
            ) { }


       
  async present(data) {
    this._loading = await this.loadingcontroller.create({
      message: data
    });
    await this._loading.present();
 
  }

  async dismiss() {
    
    return await this.loadingcontroller.dismiss().then(() => console.log('dismissed'));
  }


  async alert(header , message){
    const alert = await this.alertController.create({
      header: header, 
      message: message,
    });

    await alert.present();
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000
    });
     toast.present();
  }

  toProperCase(txt) {
    return  txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  };
 
}