import {Pipe, PipeTransform} from "@angular/core";
import {CurrencyPipe} from "@angular/common";
@Pipe({
  name: 'currency'
})
export class MyCurrencyPipe extends CurrencyPipe implements PipeTransform {

  transform(value: any, currencyCode: string): string {
    const currencyFormat = super.transform(value, currencyCode, 'symbol', '1.2-2');
    const firstDigit = currencyFormat.search(/\d/);
    return currencyFormat.substring(0, firstDigit) + ' ' + currencyFormat.substr(firstDigit);
  }

}
